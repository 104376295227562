import React, { useState } from 'react';
import { Card, Container } from './styled/Styled';
import { format } from 'date-fns';

const UserItem = ({ item }) => {

    return (
        <Container width="12" pv="8">
            <Card shadowOn flex color="color-white" width="12" pv="8" ph="16">
                <Container ellipsis width="3" children={item.email} />
                <Container width="3" children={item.name} />
                <Container width="3" children={item.phone} />
                <Container width="3" children={format(new Date(item.create_at), "dd/MM/yyyy")} />
            </Card>
        </Container>
    );
};

export default UserItem;