import React, { useState } from 'react';
import { Button, Card, Container, ContainerFlex } from './styled/Styled';
import { format } from 'date-fns';

const BudgetItem = ({ item, showImage }) => {

    return (
        <Container width="12" lg="12" pv="8">
            <Card shadowOn flex color="color-white" align="stretch" width="12" pv="8" ph="16">
                <Container width="12" lg="6" fontSize="14px">
                    <ContainerFlex>
                        <Container mr="8" fontWeight="bold" children="Nombre:" />
                        <Container children={item.name} />
                    </ContainerFlex>
                    <ContainerFlex>
                        <Container mr="8" fontWeight="bold" children="Email:" />
                        <Container children={item.email} />
                    </ContainerFlex>
                    <ContainerFlex>
                        <Container mr="8" fontWeight="bold" children="Tlf:" />
                        <Container children={item.phone} />
                    </ContainerFlex>
                    <ContainerFlex>
                        <Container mr="8" fontWeight="bold" children="Provincia:" />
                        <Container children={item.province} />
                    </ContainerFlex>
                    <ContainerFlex>
                        <Container mr="8" fontWeight="bold" children="Medidas:" />
                        <Container children={item.meters} />
                    </ContainerFlex>

                </Container>

                <Container width="12" lg="6" fontSize="14px">
                        <Container mr="8" fontWeight="bold" children="Mensaje:" />
                        <Container children={item.message} />
                </Container>

                {
                    item.image ?
                        <ContainerFlex width="12" justify="flex-end">
                            <Button flex onClick={showImage} color="color-blue" hoverColor="color-blue-light" fontColor="color-white"
                                pv="8" ph="16" children="Ver imagen" />
                        </ContainerFlex>

                        : null
                }
            </Card>
        </Container>
    );
};

export default BudgetItem;