const es = {
    actionLogin: "LOGIN",
    actionContinue: "CONTINUE",
    actionConfirmCon: "CONFIRM & CONTINUE",
    actionUpdate: "UPDATE",
    actionEdit: "EDIT",
    actionDelete: "DELETE",
    actionBack: "COME BACK",
    actionSave: "SAVE",
    actionCreate: "CREATE",
    actionYes: "YES",
    actionNo: "No",
    actionAdd: "ADD",
    actionCancel: "CANCEL",
    actionAccept: "ACCEPT",
    actionSelect: "SELECT",

    actionNew: "NEW ENTRY",
    actionExit: "Salir",

    actionShow: "Show",
    actionClose: "Close",

    msgEmail: "Email",
    msgPassword: "Password",

    msgSection1: "Galería",
    msgSection2: "Usuarios",
    msgSection3: "Presupuestos",

    /* MODAL */
    msgAreSure: "Are you sure?",

    msgSuccess: "Datos actualizados correctamente",
    msgDeleteSuccess: "Datos eliminados correctamente",

    errorOblData: "Fill all mandatory fields",
    errorEmailFormat: "Email format error",
    errorUserIncorrect: "Email/password incorrect",
    errorSend: "Communication error please wait and retry later",
    errorLength: "The number of characters has been exceded",

    msgCopyright: "Copyright&copy;2023 HUBIQUS CREATIVE SOLUTIONS. All rights reserved",

    msgFooterLegal: "Aviso legal",
    msgFooterPrivacy: "Política de privacidad",
    msgFooterCookies: "Política de Cookies",
    msgFooterCondic: "Condiciones generales del servicio",
    msgFooterCalidad: "Política de calidad",
}

export default es;