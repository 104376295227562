import React from 'react';
import { Button, Card, Container } from './styled/Styled';
import Icon from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import { serverUrlEndpoint } from './util/HttpConnection';

const GalleryItem = ({ item, deleteItem }) => {

    return (
        <Container width="12" lg="4" px="16">
            <Card shadowOn image={`${serverUrlEndpoint}image/${item.name}`} flex color="color-white" width="12" height="190px" justify="flex-end" align="flex-end">
                <Button onClick={deleteItem} flex px="8" mx="8" fontColor="color-white" color="color-red" hoverColor="color-red-dark"
                    children={<Icon size={1} path={mdiDelete} />} />
            </Card>
        </Container>
    );
};

export default GalleryItem;