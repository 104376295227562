import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import UserItem from '../components/UserItem';
import BudgetItem from '../components/BudgetItem';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { ModalImage } from '../components/util/ModalUtil';

const BudgetsView = () => {
    const [items, setItems] = useState([]);
    const { dispatch } = useContext(UserContext);

    useEffect(() => {
        HttpConnection(HttpOperations.budget,
            response => setItems(response.item), error => console.log(error), HttpMethod.get);
    }, [])

    const exportJSON = () => {
        let str = '';
        for (let i = 0; i < items.length; i++) {
            let line = '';
            for (let index in items[i]) {
                if (line !== '') line += ',';
                line += items[i][index];
            }
            str += line + '\r\n';
        }
        //Create blob
        const csvData = str;
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'budgets.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const showImage = (item) => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalImage image={item.image} /> } })
    }

    return (
        <ContainerFlex width="12" justify="center">
            <ContainerFlex width="12" lg="8" ph="16">

                <ContainerFlex width="12" mt="32" justify="space-between" align="center">
                    <Container fontColor="color-white" fontWeight="500" fontSize="38px" children={<TextLocalized children="msgSection3" />} />
                    <Button onClick={exportJSON} color="color-blue" hoverColor="color-blue-light" fontColor="color-white" pv="8" ph="16" children="Exportar CSV" />
                </ContainerFlex>
                <ContainerFlex width="12">
                    {
                        items.map((item, index) => <BudgetItem key={index} item={item} showImage={() => showImage(item)} />)
                    }
                </ContainerFlex>

            </ContainerFlex>

        </ContainerFlex>
    );
};

export default BudgetsView;