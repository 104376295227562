import React, { useEffect, useState } from 'react';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import UserItem from '../components/UserItem';

const UsersView = () => {

    const [items, setItems] = useState([]);

    const getImages = () => HttpConnection(HttpOperations.users,
        response => setItems(response.item), error => console.log(error), HttpMethod.get);

    useEffect(() => {
        getImages();
    }, [])

    const exportJSON = () => {
        let str = '';
        for (let i = 0; i < items.length; i++) {
            let line = '';
            for (let index in items[i]) {
                if (line !== '') line += ',';
                line += items[i][index];
            }
            str += line + '\r\n';
        }
        //Create blob
        const csvData = str;
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'data.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    return (
        <ContainerFlex width="12" justify="center">
            <ContainerFlex width="12" lg="8" ph="16">

                <ContainerFlex width="12" mt="32" justify="space-between" align="center">
                    <Container fontColor="color-white" fontWeight="500" fontSize="38px" children={<TextLocalized children="msgSection2" />} />

                    <Button onClick={exportJSON} color="color-blue" hoverColor="color-blue-light" fontColor="color-white" pv="8" ph="16" children="Exportar CSV" />
                </ContainerFlex>
                <Card flex color="color-black" fontColor="color-white" fontWeight="500" mv="16" width="12" pv="12" ph="16">
                    <Container width="3" children={"Email"} />
                    <Container width="3" children={"Nombre"} />
                    <Container width="3" children={"Teléfono"} />
                    <Container width="3" children={"Fecha registro"} />
                </Card>
                <ContainerFlex width="12">
                    {
                        items.map((item, index) => <UserItem key={index} item={item} />)
                    }
                </ContainerFlex>

            </ContainerFlex>

        </ContainerFlex>
    );
};

export default UsersView;