import './css/normalize.css';
import './css/bootstrap-grid.css';
import './css/App.css';

import { LanguageProvider } from './components/providers/LanguageProvider';
import { UserContext, UserProvider } from './components/providers/UserProvider';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginView from './views/LoginView';

import { Fragment, useContext } from 'react';
import { NotificationView } from './components/util/NotificationUtil';
import ModalView from './components/util/ModalUtil';
import { Container } from './components/styled/Styled';
import Header from './components/Header';
import { Footer } from './components/Footer';
import GalleryView from './views/GalleryView';
import UsersView from './views/UsersView';
import BudgetsView from './views/BudgetsView';

const SwitchView = () => {
  const { state } = useContext(UserContext);

  return <Fragment>
    <Container width="12" height="100vh" overflowY="auto" color="color-white">
      <Router basename="">
        {
          state.login ?
            <Fragment>
              <Header />
              <Container width="12" minheight="calc(100vh - 54px)" color="color-primary" overflowY="auto">
                <Routes>
                  <Route exact path="/" element={<GalleryView />} />
                  <Route exact path="/users" element={<UsersView />} />
                  <Route exact path="/budgets" element={<BudgetsView />} />

                </Routes>
              </Container>
              <Footer />
            </Fragment>
            :
            <Routes>
              <Route exact={true} path="*" element={<LoginView />} />
            </Routes>
        }
      </Router>
      {state.notification.show ? <NotificationView /> : null}
    </Container>
    {state.modal.show ? <ModalView children={state.modal.body} /> : null}
  </Fragment>
}

const App = () => <LanguageProvider children={<UserProvider children={<SwitchView />} />} />

export default App;