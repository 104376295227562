import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Button, Container, ContainerFlex, SpinnerLoader, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpFormConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import GalleryItem from '../components/GalleryItem';
import { UserAction, UserContext } from '../components/providers/UserProvider';

const GalleryView = () => {

    const [items, setItems] = useState([]);
    const [fileSelected, setFileSelected] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const fileRef = useRef();
    const { dispatch } = useContext(UserContext);

    const getImages = () => HttpConnection(HttpOperations.gallery,
        response => setItems(response.item), error => console.log(error), HttpMethod.get);

    useEffect(() => {
        getImages();
    }, [])


    const selectFile = () => {
        if (fileRef.current.files.length > 0) {
            setFileSelected(true);
        }
    }

    const cleanSelectFile = () => {
        fileRef.current.value = null;
        setFileSelected(false)
    }

    const sendData = () => {
        let formData = new FormData();
        formData.append('file', fileRef.current.files[0]);

        setIsSending(true);

        HttpFormConnection(`admin/${HttpOperations.gallery}`,
            response => {
                //Update images
                getImages();
                setIsSending(false);
                //Clean file
                fileRef.current.value = null;
                setFileSelected(false)
                //Notify user
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } })
            }, error => {
                setIsSending(false);
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } })
            }, HttpMethod.post, formData);
    }

    const deleteItem = (id) => {
        HttpConnection(`admin/${HttpOperations.gallery}/${id}`,
            response => {
                //Update images
                getImages();
                //Notify user
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgDeleteSuccess" } })
            },
            error => {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } })
            }, HttpMethod.delete);
    }

    return (
        <ContainerFlex width="12" justify="center">
            <ContainerFlex width="12" lg="8">

                <ContainerFlex width="12" mt="32" ph="16" align="center">
                    <Container fontColor="color-white" fontWeight="500" fontSize="38px" children={<TextLocalized children="msgSection1" />} />

                    <ContainerFlex width="wrap" px="16" justify="flex-end">
                        <input hidden onChange={selectFile} ref={fileRef} type='file' />
                        {
                            isSending ? <SpinnerLoader /> :
                                (
                                    fileSelected ?
                                        <Fragment>
                                            <Button onClick={cleanSelectFile} color="color-red"
                                                hoverColor="color-red-dark" fontColor="color-white" mr="8" pv="8" ph="16" children="Elimiar archivo" />
                                            <Button onClick={sendData} color="color-black"
                                                hoverColor="color-black-dark" fontColor="color-white" pv="8" ph="16" children="Enviar archivo" />
                                        </Fragment>
                                        :
                                        <Button onClick={() => fileRef.current.click()} color="color-blue"
                                            hoverColor="color-blue-light" fontColor="color-white" pv="8" ph="16" children="Seleccionar archivo" />
                                )
                        }
                    </ContainerFlex>
                </ContainerFlex>

                <ContainerFlex width="12">
                    {
                        items.map((item, index) => <GalleryItem key={index} item={item} deleteItem={() => deleteItem(item.id_image)} />)
                    }
                </ContainerFlex>

            </ContainerFlex>

        </ContainerFlex>
    );
};

export default GalleryView;